import _memoize2 from "@emotion/memoize";
import _isPropValid2 from "@emotion/is-prop-valid";
import _styledSystem2 from "styled-system";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.createShouldForwardProp = exports.props = void 0;

var _memoize = _interopRequireDefault(_memoize2);

var _isPropValid = _interopRequireDefault(_isPropValid2);

var _styledSystem = _styledSystem2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var all = (0, _styledSystem.compose)(_styledSystem.space, _styledSystem.typography, _styledSystem.color, _styledSystem.layout, _styledSystem.flexbox, _styledSystem.border, _styledSystem.background, _styledSystem.position, _styledSystem.grid, _styledSystem.shadow, _styledSystem.buttonStyle, _styledSystem.textStyle, _styledSystem.colorStyle);
var props = all.propNames;
exports.props = props;

var createShouldForwardProp = function createShouldForwardProp(props) {
  var regex = new RegExp("^(" + props.join("|") + ")$");
  return (0, _memoize["default"])(function (prop) {
    return (0, _isPropValid["default"])(prop) && !regex.test(prop);
  });
};

exports.createShouldForwardProp = createShouldForwardProp;

var _default = createShouldForwardProp(props);

exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _createShouldForwardProp = exports.createShouldForwardProp,
      _props = exports.props;
export { _createShouldForwardProp as createShouldForwardProp, _props as props };